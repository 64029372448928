<template>
<div>
  <div>
    <Intercom :ic_obj="{ic_parent:item.item_id, ic_parent_type:17}" mode="DongTai" @submit="submit_handler" />
  </div>
  <div v-for="dItem in dtSortList" class="ma-5">
    <DongTaiItem :dObject="dItem" />
    <v-divider light></v-divider>
  </div>

</div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  name: 'ItemDynamic',
  props: ['item'],
  components: {
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    Operating: () => import('@/components/common/operating/Operating.vue'),
    Intercom: () => import("@/components/common/Intercom/Intercom.vue"),
    DongTaiItem: () => import("@/components/common/DongTaiItem.vue"),
  },
  data() {
    return {
      dtList: [],
      enableIcSubmitBtn: false,
    }
  },
  mounted() {
    this.updateList();
  },
  computed: {
    ...mapGetters('task', ['tasks']),
    ...mapGetters('milestone', ['milestones', 'defaultMilestone']),
    ...mapGetters("intercom", ["intercom"]),
    ...mapGetters("auth", ["authUser"]),
    dtSortList() {
      this.dtList.sort(function (a, b) {
        if (a.updated_at > b.updated_at) {
          return -1;
        } else {
          return 1;
        }
      });
      return this.dtList;
    },
  },
  methods: {
    ...mapActions('task', ['getTasks']),
    ...mapActions('milestone', ['getMilestones']),
    ...mapActions('intercom', ['getIntercom']),

    submit_handler(param) {
      param.created_at = new Date();
      param.ic_sender = this.authUser.id;
      param.ic_sender_name = this.authUser.employeeName;

      this.dtList.push({
        mode: 'intercom',
        updated_at: param.created_at,
        obj: param,
      });
    },
    updateList() {
      this.dtList = [];

      this.getTasks({
        task_parent_item: this.item.item_id
      }).then(res => {
        this.tasks.forEach(e => {
          this.dtList.push({
            mode: 'task',
            updated_at: e.updated_at,
            obj: e,
          });
        });
      });

      this.getMilestones({
        ms_parent_item: this.item.item_id
      }).then(res => {
        this.milestones.forEach(e => {
          this.dtList.push({
            mode: 'milestone',
            updated_at: e.updated_at,
            obj: e,
          });
        });
      });

      this.getIntercom({
        ic_parent: this.item.item_id,
        ic_parent_type: 17
      }).then(res => {
        this.intercom.forEach(e => {
          this.dtList.push({
            mode: 'intercom',
            updated_at: e.created_at,
            obj: e,
          });
        });
      });

      this.dtList.push({
        mode: 'item',
        updated_at: this.item.updated_at,
        obj: this.item,
      });
    }
  }
}
</script>
